import React, {useEffect, useState} from "react";
import Select, { components } from "react-select";

const CustomClearText = () => <>Xóa</>;
const ClearIndicator = (props: any) => {
  const {
    children = <CustomClearText />,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
      <div
          {...restInnerProps}
          ref={ref}
          style={getStyles("clearIndicator", props)}
      >
        <div style={{ padding: "0px 5px" }}>{children}</div>
      </div>
  );
};

const ClearIndicatorStyles = (base: any, state: any) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "blue" : "black",
});

interface OptionType {
  value: string;
  label: string;
}

interface MultiSelectFilterProps {
  isDisabled?: boolean;
  defaultValue?: any;
  value?: any;
  closeMenuOnSelect?: boolean;
  options: any;
  isMulti?: boolean;
  placeholder?: string;
  className?: string;
  isSearch?: boolean;
  isClearable?: boolean;
  HandleOption?: (value: any) => void;
  onOpen?: () => void;
  canAddNewOption?: boolean;
}

const MultiSelectFilter = ({
                             isDisabled = false,
                             value,
                             defaultValue = {},
                             closeMenuOnSelect = false,
                             options,
                             isMulti = false,
                             HandleOption,
                             placeholder = "Chọn",
                             isSearch = false,
                             className = "",
                             isClearable,
                             onOpen,
                             canAddNewOption = false, // new prop to allow adding new options
                           }: MultiSelectFilterProps) => {
  const [currentOptions, setCurrentOptions] = useState(options);

  const onChange = (selected: any) => {
    if (isMulti) {
      HandleOption?.(selected);
    } else {
      HandleOption?.(selected?.value);
    }
  };

  const onCheckOpen = () => {
    if (currentOptions?.length === 0 || !currentOptions) {
      onOpen?.();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!canAddNewOption) return;

    if (event.key === "Enter" && event.currentTarget) {
      const inputValue = (event.target as HTMLInputElement).value;
      if (!inputValue.trim()) return;

      const newOption = { value: inputValue, label: inputValue };

      if (!currentOptions?.some((option: any) => option.value === inputValue)) {
        setCurrentOptions([...currentOptions, newOption]);
        // Optionally, you can trigger the `onChange` function to add it to the selected values immediately
        if (isMulti) {
          onChange([...value, newOption]);
        } else {
          onChange(newOption);
        }
      }
    }
  };
    useEffect(() => {
        setCurrentOptions(options);
    }, [options]);
  return (
      <Select
          isDisabled={isDisabled}
          className={`select-filter form-select h-auto py-0 ${className}`}
          placeholder={isSearch ? "Tất cả" : placeholder}
          isSearchable={true}
          menuPosition="fixed"
          closeMenuOnSelect={closeMenuOnSelect}
          isClearable={isClearable}
          components={{ ClearIndicator }}
          styles={{ clearIndicator: ClearIndicatorStyles, menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          isMulti={isMulti}
          noOptionsMessage={() => "Đã tải hết dữ liệu"}
          options={currentOptions}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onMenuOpen={onCheckOpen}
          onKeyDown={handleKeyDown} // Handle 'Enter' key for adding new option
      />
  );
};

export default MultiSelectFilter;
